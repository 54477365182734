import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Facility from "./Components/Facility/Facility";
import About from "./Components/About/About";
import ContactUs from "./Components/Contact/ContactUs";
import { Element } from "react-scroll";
import { LiaWhatsapp } from "react-icons/lia";
import { SiGmail } from "react-icons/si";

const App = () => {
  const PHONE = "+918999835317";
  const MAIL = "7jenodev@gmail.com";
  const phoneLink = `https://wa.me/${PHONE}?text=Hello%20there!`;
  const subject = "Hello";
  const body = "Hi there!";
  const mailtoLink = `mailto:${MAIL}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  return (
    <>
      <div className="BodyContainer">
        <Navbar />
        <Home />
        <Element name="facility">
          <Facility />
        </Element>
        <Element name="contact">
          <ContactUs />
        </Element>
      </div>
      <div className="Foot__flex" >
        <div className="Footer1">Developed by Sevinjeno</div>
        <div>
        <span className="whatsapp">
          <a href={phoneLink}>
            <LiaWhatsapp  color="green"   />
          </a>
        </span>
        <span>
          {" "}
          <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
            {" "}
            <SiGmail />{" "}
          </a>{" "}
        </span>

        </div>
      </div>
    </>
  );
};

export default App;
